import 'react-perfect-scrollbar/dist/css/styles.css'
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// import 'react-quill/dist/quill.snow.css'
import 'suneditor/dist/css/suneditor.min.css'
import 'nprogress/nprogress.css'
// import './__mocks__'
import { StrictMode, createContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider as HP } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/client/react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import App from './App'
import { AuthProvider } from './contexts/JWTContext'
import { SettingsProvider } from './contexts/SettingsContext'
import reportWebVitals from './reportWebVitals'
import store from './store'
import SplashScreen from './components/common/SplashScreen'

const keycloak = Keycloak({
  realm: 'master',
  clientId: 'client',
  url: process.env.REACT_APP_KEYCLOAK_URL
})

const HelmetProvider = HP as any
const client = new ApolloClient({
  cache: new InMemoryCache()
})

export const ApolloClientContext = createContext(undefined)
export const ApolloClientContextProvider = ({ children }) => {
  const [apolloClient, setApolloClient] = useState(client)
  return (
    <ApolloClientContext.Provider value={[apolloClient, setApolloClient]}>
      {children}
    </ApolloClientContext.Provider>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <HelmetProvider>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
        LoadingComponent={<SplashScreen />}
      >
        <ApolloClientContextProvider>
          <ApolloClientContext.Consumer>
            {([apolloClient, setApolloClient]) => (
              <ApolloProvider client={apolloClient}>
                <ReduxProvider store={store}>
                  <StyledEngineProvider injectFirst>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <SettingsProvider>
                        <AuthProvider>
                          <StrictMode>
                            <App />
                          </StrictMode>
                        </AuthProvider>
                      </SettingsProvider>
                    </LocalizationProvider>
                  </StyledEngineProvider>
                </ReduxProvider>
              </ApolloProvider>
            )}
          </ApolloClientContext.Consumer>
        </ApolloClientContextProvider>
      </ReactKeycloakProvider>
    </HelmetProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to enable client cache, register instead.
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
