import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Box, Drawer } from '@mui/material'
import Stack from '@mui/material/Stack'
import useSidebarSectionsByRole from 'src/hooks/useSidebarSectionsByRole'
import NavSection from './NavSection'
import Scrollbar from './Scrollbar'

const DashboardSidebar = () => {
  const location = useLocation()
  const sections = useSidebarSectionsByRole()

  // const userProfile = useQuery(GET_USER_PROFILE, {
  //   variables: { userId: user?.email },
  //   skip: !user?.email
  // }).data?.profile[0]

  const content = (
    <Stack
      direction="column"
      sx={{
        height: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          m: 3,
          mb: 2
        }}
      >
        <RouterLink to="/">
          <Box
            component="img"
            alt="Logo"
            src="/static/logo-dark.svg"
            sx={{ width: 124 }}
            display="flex"
            alignItems="center"
          />
        </RouterLink>
      </Box>
      <Box overflow="hidden" flex={1}>
        <Scrollbar options={{ suppressScrollX: true }}>
          <Stack justifyContent="space-between" sx={{ height: '100%' }}>
            <Stack>
              <Stack sx={{ p: 2 }}>
                {sections.map((section) => (
                  <NavSection
                    key={section.title}
                    pathname={location.pathname}
                    sx={{
                      '& + &': {
                        mt: 2
                      }
                    }}
                    {...section}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Scrollbar>
      </Box>
    </Stack>
  )

  return (
    <Drawer
      elevation={12}
      anchor="left"
      open
      PaperProps={{
        sx: {
          bgcolor: '#303148',
          height: '100% !important',
          width: 240
          // border: 'none'
        }
      }}
      variant="persistent"
    >
      {content}
    </Drawer>
  )
}

export default DashboardSidebar
