import { useRef, useState, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material'
import Stack from '@mui/material/Stack'
import { useQuery } from '@apollo/client'
import { toast } from 'react-hot-toast'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import useAuth from '../../hooks/useAuth'
import { GET_USER_PROFILE } from '../../operations/queries/getUserProfile'
import { ROLE_MAP } from '../../constants'

const AccountPopover = () => {
  const anchorRef = useRef(null)
  const { user, logout, role } = useAuth()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const userProfile = useQuery(GET_USER_PROFILE, {
    variables: { userId: user?.email },
    skip: !user?.email
  })

  const handleLogout = async () => {
    try {
      await logout()
      handleClose()
    } catch (err) {
      toast.error('Có lỗi xảy ra khi đăng xuất')
    }
  }

  const handleClickProfile = () => {
    handleClose()
    navigate('/personal')
  }

  if (!userProfile.loading && userProfile.error) {
  }

  if (userProfile.loading) {
    return (
      <Stack alignItems="center" spacing={1} direction="row">
        <Skeleton variant="circular" width={40} height={40} />
        <Stack spacing={0.25} direction="column">
          <Typography variant="subtitle2" width={100}>
            <Skeleton width="100%" />
          </Typography>
          <Typography variant="subtitle2" width={100}>
            <Skeleton width="70%" />
          </Typography>
        </Stack>
      </Stack>
    )
  }

  const myProfile = userProfile.data?.profile[0]

  if (myProfile) {
    return (
      <>
        <ButtonBase
          disableRipple
          sx={{ backgroundColor: 'transparent' }}
          onClick={handleClick}
          ref={anchorRef}
        >
          <Stack direction="row" alignItems="center">
            <Avatar
              src={`${myProfile.profile_picture_s3_url}?lastmod=${new Date()}`}
              sx={{
                height: 40,
                width: 40
              }}
            />
            <Stack sx={{ pl: 1, flex: 1 }}>
              <Tooltip title={user?.email}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left'
                  }}
                >
                  {myProfile?.first_name} {myProfile?.last_name}
                </Typography>
              </Tooltip>
              <Stack alignItems="center" direction="row" spacing={0.25}>
                <Typography color="text.secondary" textAlign="left" variant="subtitle2">
                  {ROLE_MAP[role]}
                </Typography>
                <ArrowDropDownIcon />
              </Stack>
            </Stack>
          </Stack>
        </ButtonBase>

        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'profile-button'
          }}
          sx={{ width: 210, maxWidth: '100%' }}
        >
          <MenuItem onClick={handleClickProfile}>
            <ListItemIcon>
              <PersonOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Cá nhân</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Đăng xuất</ListItemText>
          </MenuItem>
        </Menu>
      </>
    )
  }

  return null
}

export default AccountPopover
