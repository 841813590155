const vi = {
  translation: {
    dashboard: 'Trang Chủ',
    account: 'Tài Khoản',
    manageBonus: 'Quản Lý Bonus',
    manageSalaryBonus: 'Quản Lý Lương & Bonus',
    salesDocuments: 'Tài liệu dành cho sale',
    manageTeamBonus: 'Quản Lý Bonus Team',
    trainingDocuments: 'Tài Liệu Training',
    support: 'Hỗ Trợ',
    manageInformation: 'Quản Lý Thông Tin',
    userInformation: 'Thông Tin Người Dùng',
    assignOffice: 'Chỉ Định Văn Phòng',
    officesInformation: 'Thông Tin Văn Phòng',
    teamsInformation: 'Thông Tin Team',
    employeesInformation: 'Thông Tin Nhân Viên',
    userPermission: 'Phân Quyền Người Dùng',
    bonusStatistics: 'Thống Kê Bonus',
    incomeStatistics: 'Thống kê thu nhập',
    totalIncome: 'Tổng thu nhập',
    approveBonusRequests: 'Duyệt Lệnh Rút Bonus',
    approverSalaryRequests: 'Duyệt Lệnh Rút Lương',
    approverSalary: 'Duyệt lương',
    rejectedSalary: 'Từ chối duyệt lương',
    responseComplaints: 'Phản Hồi Khiếu Nại',
    bonusDetails: 'Chi Tiết Bonus',
    salaryBonusDetails: 'Chi tiết lương và bonus',
    manageNotifications: 'Quản Lý Thông Báo',
    totalBonusThisMonth: 'Tổng Bonus Trong Tháng',
    totalFtdThisMonth: 'Tổng FTD Trong Tháng',
    totalCustomers: 'Số Khách Hàng',
    totalPersonalBonus: 'Tổng Bonus Cá Nhân',
    totalTeamBonus: 'Tổng Bonus Team',
    month: 'Tháng',
    totalBonus: 'Tổng Bonus',
    totalFtd: 'Tổng FTD',
    bonusTicket: 'Phiếu Bonus',
    status: 'Trạng Thái',
    bonusStatus: 'Trạng thái bonus',
    salaryBonus: 'Trạng thái lương',
    withdrawBonus: 'Rút Bonus',
    withdrawSalary: 'Rút lương',
    withdraw: 'Rút',
    withdrawSalaryBonus: 'Rút lương/Bonus',
    withdrawBoth: 'Rút cả hai',
    complain: 'Khiếu nại',
    details: 'Chi tiết',
    viewBonusTicket: 'Xem Phiếu Bonus',
    folderName: 'Tên File',
    addedBy: 'Được Thêm Bởi',
    lastModified: 'Sửa Đổi Lần Cuối',
    Documents: 'Tài Liệu',
    viewDocument: 'Xem Tài Liệu',
    removePicture: 'Xóa Ảnh',
    updatePicture: 'Cập Nhật Ảnh',
    addPicture: 'Thêm ảnh đại diện',
    profile: 'Thông Tin Cá Nhân',
    identity: 'CMND/CCCD',
    frontIdentity: 'CMND/CCCD (Mặt Trước)',
    backIdentity: 'CMND/CCCD (Mặt Sau)',
    changeCover: 'Thay Đổi',
    usernameLogin: 'Dùng để đăng nhập',
    changePassword: 'Thay Đổi Mật Khẩu',
    oldPassword: 'Mật khẩu cũ',
    newPassword: 'Mật khẩu mới',
    passwordConfirmation: 'Nhập lại mật khẩu',
    password: 'Mật khẩu đăng nhập',
    signup: 'Đăng ký tài khoản',
    createUser: 'Tạo Tài Khoản',
    rowsPerPage: 'Số hàng trên trang:',
    bonusHistory: 'Lịch Sử Bonus',
    bonusSalaryBonus: 'Lịch Sử Lương & Bonus',
    fullName: 'Tên Đầy Đủ:',
    office: 'Văn Phòng',
    gender: 'Giới Tính',
    phone: 'Số Điện Thoại',
    offices: 'Văn Phòng',
    userNotOffice: 'Chưa Có Văn Phòng',
    userNotTeam: 'Chưa Có Team',
    cancel: 'Huỷ',
    male: 'Nam',
    approverBy: 'Được duyệt bởi',
    exportFile: 'Xuất File',
    exportPage: 'Xuất data được lọc',
    exportAll: 'Xuất tất cả',
    salesTeam: 'Đội sales',
    viewDetails: 'Xem Chi Tiết',
    nameStaff: 'Tên Nhân Viên',
    errorOccurred: 'Có lỗi đã xảy ra!',
    searchPlaceholder: 'Tìm kiếm...',
    roles: 'Chức vụ',
    username: 'Tên tài khoản',
    active: 'Trạng thái',
    activeBonus: 'Trạng thái của bonus',
    activeSalary: 'Trạng thái của lương',
    enable: 'Có hiệu lực',
    disable: 'Vô hiệu hóa',
    update: 'Cập Nhật',
    created: 'Đã tạo',
    notCreated: 'Chưa tạo',
    name: 'Tên nhân viên',
    deleteUser: 'Xóa Người Dùng',
    deleteTeam: 'Xóa Team',
    deleteOffice: 'Xóa Văn Phòng',
    deletePrompt:
      'Bạn có chắc là bạn muốn xoá người dùng này không? Hành động này không thể hoàn tác!',
    deletePromptTeam:
      'Bạn có chắc là bạn muốn xóa team này không? Hành động này không thể hoàn tác!',
    deletePromptOffice:
      'Bạn có chắc là bạn muốn xóa văn phòng này không? Hành động này không thể hoàn tác!',
    restoreUser: 'Người dùng đã tồn tại trong hệ thống, bạn có muốn khôi phục',
    cannotRequestBonusPrompt: 'Bạn cần phải cập nhật đầy đủ thông tin trước khi yêu cầu rút bonus!',
    cannotComplaintBonusPrompt: 'Bạn cần phải cập nhật đầy đủ thông tin trước khi khiếu nại bonus!',
    blockComplaintPrompt:
      'Bạn có chắc là bạn muốn khoá khiếu nại của bonus này? Sẽ có hiệu lực trong vòng 7 ngày!',
    unBlockComplaintPrompt: 'Bạn có chắc là bạn muốn mở khóa khiếu nại của bonus này',
    createNewUserPrompt:
      'Tạo mới sẽ xoá bỏ dữ liệu cũ của người dùng này và thiết lập tài khoản mới',
    restoreUserPrompt: 'Khôi phục sẽ giữ lại dữ liệu và lịch sử sử dụng của tài khoản này',
    confirmEmail: 'Vui lòng xác nhận email trước khi đăng nhập!',
    sentEmailTo: 'Chúng tôi đã gửi thông báo đến ',
    checkEmail: 'Xin vui lòng kiểm tra email của bạn!',
    login: 'Đăng nhập',
    rejectBonusPrompt: 'Bạn có chắc bạn muốn từ chối bonus này? Hành động này không thể hoàn tác!',
    rejectBonusAllPrompt:
      'Bạn có chắc bạn muốn từ chối các bonus đã chọn? Hành động này không thể hoàn tác!',
    acceptAllBonus:
      'Bạn có chắc bạn muốn phê duyệt tất cả các bonus đã chọn? Hành động này không thể hoàn tác!',
    acceptAllSalary:
      'Bạn có chắc bạn muốn phê duyệt tất cả các số tiền lương đã chọn? Hành động này không thể hoàn tác!',
    requestId: 'Request ID',
    requestedDate: 'Ngày Yêu Cầu',
    withdrawDateSalary: 'Ngày rút lương',
    withdrawDateBonus: 'Ngày rút bonus',
    createdAt: 'Ngày tạo',
    monthBonus: 'Bonus Tháng',
    monthSalary: 'Lương Tháng',
    bonusAmount: 'Số Tiền Bonus',
    amount: 'Số tiền',
    approve: 'Duyệt',
    approveAll: 'Duyệt Tất Cả',
    rejectAll: 'Từ Chôi Tất Cả',
    resendAllEmail: 'Gửi Tất Cả',
    accept: 'Xác nhận',
    pending: 'Chưa rút',
    reject: 'Từ chối',
    waiting: 'Chờ duyệt',
    hasApproved: 'Đã duyệt',
    hasTransfer: 'Đã chuyển',
    transferFail: 'Chuyển tiền thất bại',
    approveBonus: 'Duyệt Bonus',
    approveSalary: 'Duyệt Lương',
    transferSalaryType: 'Chuyển tiền thành công',
    transferSalaryError: 'Chuyển tiền thất bại',
    approveAllBonus: 'Duyệt Tất Cả Bonus Đã Chọn',
    approveAllSalary: 'Duyệt Tất Cả Tiền Lương Đã Chọn',
    rejectBonus: 'Từ chối bonus',
    rejectSalary: 'Từ chối lương',
    bonusApprovedSuccessfully: 'Duyệt bonus thành công',
    salaryApprovedSuccessfully: 'Duyệt lương thành công',
    incomePeriod: 'Kỳ thu nhập',
    totalWithdrawableMoney: 'Tổng tiền có thể rút',
    content: 'Nội dung',
    email: 'Email',
    complainBonus: 'Hãy liệt kê chi tiết mà bạn từ chối bonus!',
    complainSalary: 'Hãy liệt kê chi tiết mà bạn từ chối lương!',
    complainedAccount: 'Tài khoản khiếu nại',
    complainedDate: 'Ngày khiếu nại',
    complaintsDetails: 'Chi Tiết Khiếu Nại',
    months: 'Tháng',
    teams: 'Team',
    selectImage: 'Chọn Hình',
    dropImage: 'Kéo thả hình ảnh ở đây hoặc duyệt ảnh trong máy của bạn.',
    sales: 'Sales',
    markAsReadSure: 'Bạn có chắc rằng bạn muốn đánh dấu đã đọc?',
    bonusApprovedFailed: 'Duyệt bonus không thành công',
    salaryApprovedFailed: 'Duyệt lương không thành công',
    customer: 'Khách hàng',
    bonus: 'Tiền thưởng',
    userId: 'ID người dùng',
    bank: 'Ngân hàng',
    numberBank: 'Số tài khoản ngân hàng',
    accountHolder: 'Tên chủ tài khoản ngân hàng',
    approvedBonusNoti: 'đã duyệt bonus của bạn',
    approvedSalaryNoti: 'đã duyệt lương của bạn',
    rejectedBonusNoti: 'đã từ chối duyệt bonus của bạn',
    rejectedSalaryNoti: 'đã từ chối duyệt lương của bạn',
    complaintBonusNoti: 'đã khiếu nại về bonus',
    transferSalary: 'đã chuyển tiền lương thành công đến bạn',
    transferError: 'đã chuyển tiền lương thất bại đến bạn',
    transferredBonusNoti: 'đã chuyển tiền bonus đến tài khoản của bạn',
    requestBonusNoti: 'đã yêu cầu duyệt bonus',
    requestSalaryNoti: 'đã yêu cầu duyệt lương',
    bonusForSM: 'Tiền thưởng cho Sales Manager',
    bonusForManager: 'Tiền thưởng cho Manager',
    salary: 'Tiền lương',
    totalBonusSalary: 'Tổng tiền thưởng và lương',
    downloadTemplate: 'Tải bảng mẫu',
    noOption: 'Không có tùy chọn',
    officeReceived: 'VP nhận được bonus',
    freelanceStaff: 'Nhân viên tự do',
    consultingCollaborators: 'Cộng tác viên tư vấn',
    modals: {
      titles: {
        createOffice: 'Tạo Văn Phòng',
        editOffice: 'Sửa Văn Phòng',
        createTeam: 'Tạo Đội Sales',
        editTeam: 'Sửa Đội Sales',
        createEmployee: 'Tạo Nhân Viên',
        editEmployee: 'Sửa Nhân Viên',
        editUser: 'Sửa Tài Khoản',
        createUser: 'Tạo Tài Khoản',
        resendEmail: 'Gửi lại email',
        resendAllEmail: 'Gửi lại tất cả email',
        employeeDetails: 'Chi tiết nhân viên',
        createComplaint: 'Tạo Khiếu Nại',
        uploadEmployees: 'Nhân viên tải lên hàng loạt',
        answerComplaint: 'Trả lời khiếu nại',
        upLoadTransferBonus: 'Chuyển tiền thưởng',
        upLoadTransferSalary: 'Chuyển tiền lương',
        changeEmployeesInfo: 'Thay đổi thông tin nhân viên',
        confirmInfoMerchant: 'Xác nhận thông tin'
      },
      subtitles: {
        resendEmail:
          'Bạn có thể gửi mail lại bất cứ lúc nào nếu thành viên trong team có vấn đề trong việc tạo tài khoản.',
        createComplaint: 'Bạn cần trợ giúp hoặc cần giải quyết thắc mắc về bonus?',
        resendAllEmail:
          'Bạn có thể gửi lại tất cả mail mà bạn đã chọn  bất cứ lúc nào nếu thành viên trong team có vấn đề trong việc tạo tài khoản.'
      },
      buttons: {
        cancel: 'Hủy',
        confirm: 'Xác Nhận',
        createNew: 'Tạo mới',
        Restore: 'Khôi phục',
        continueSend: 'Tiếp tục gửi',
        paymentMethod: 'Cập nhật hình thức thanh toán',
        goBack: 'Quay lại'
      }
    },
    buttons: {
      createOffice: 'Tạo Văn Phòng',
      createTeam: 'Tạo Team',
      createEmployee: 'Tạo Nhân Viên',
      exportFile: 'Xuất File',
      createUser: 'Tạo Tài Khoản',
      logout: 'Đăng Xuất',
      backToDashboard: 'Trở Về Trang Chủ',
      send: 'Gửi Đi',
      blockComplaint: 'Chặn khiếu nại',
      writeComplaint: 'Viết khiếu nại',
      unBlockComplaint: 'Mở khiếu nại',
      importEmployees: 'Thêm hàng loạt nhân viên',
      massUpdate: 'Cập nhật hoàng loạt',
      answer: 'Trả lời',
      importTransfersBonus: 'Chuyển tiền thưởng',
      importTransfersSalary: 'Chuyển tiền lương',
      refresh: 'Làm mới',
      syncData: 'Đồng bộ dữ liệu',
      createComplaint: 'Tạo Khiếu Nại',
      closeComplaint: 'Đóng khiếu nại'
    },
    inputs: {
      placeholders: {
        searchBoxPlaceholder: 'Tìm kiếm...'
      },
      errorTexts: {
        required: 'Bạn cần phải điền vào ô này!',
        mustChoose: 'Bạn cần phải chọn một!',
        mustBeAnumber: 'Bạn chỉ được nhập số!',
        emailInvalidate: 'Email không đúng định dạng!',
        phoneInvalidate: 'Số điện thoại không đúng định dạng!',
        userNameInvalidate: 'Tên đăng nhập chỉ được bao gồm chữ và số!',
        password: 'Mật khẩu phải từ 8 ký tự trở lên!',
        confirmPassword: 'Mật khẩu chưa trùng khớp!',
        fullNameCapitalize: 'Họ tên phải viết hoa chữ cái đầu!',
        onlyNumber: 'Chỉ bao gồm số',
        spaces: 'Vui lòng không nhập khoảng trống!'
      }
    },
    tables: {
      labelRowsPerPage: 'Số hàng mỗi trang:',
      labelDisplayedRows: {
        of: 'của',
        moreThan: 'có nhiều hơn'
      }
    },
    snackbars: {
      messages: {
        error: {
          createError: 'Tạo không thành công',
          editedError: 'Cập nhật không thành công',
          emailIsExit: 'Email đã tồn tại!',
          deleteError: 'Xóa không thành công',
          complaintError: 'Khiếu nại không thành công',
          requestBonus: 'Yêu cầu rút tiền thưởng không thành công',
          requestSalary: 'Yêu cầu rút tiền lương không thành công',
          rejectBonusError: 'Từ chối duyệt bonus thất bại!',
          rejectSalaryError: 'Từ chối duyệt lương thất bại!',
          removeAvatarError: 'Xóa ảnh đại diện không thành công',
          assignOfficeError: 'Chỉ định văn phòng thất bại!',
          bonusBlocked: 'Khiếu nại của bonus này đã bị khóa bởi admin!',
          sendAllEmailError: 'Gửi tất cả email thất bại!',
          bonusApprovedFailed: 'Duyệt bonus không thành công',
          failedToTransferBonus: 'Không thể chuyển tiền thưởng!',
          massUpdateEmployeesError: 'Cập nhật hàng loạt nhân viên không thành công',
          markComplainError: 'Đánh dấu khiếu nại không thành công'
        },
        success: {
          createdSuccessful: 'Tạo thành công',
          editedSuccessful: 'Cập nhật thành công',
          restoreSuccessful: 'Khôi phục thành công',
          deleteSuccessful: 'Xóa thành công',
          complainedSuccessful: 'Khiếu nại thành công',
          requestBonus: 'Gửi yêu cầu rút tiền thưởng thành công',
          requestSalary: 'Gửi yêu cầu rút tiền lương thành công',
          rejectBonusSuccessful: 'Từ chối duyệt bonus thành công',
          rejectSalarySuccessful: 'Từ chối duyệt lương thành công',
          removeAvatarSuccessful: 'Xóa ảnh đại diện thành công',
          assignOfficeSuccessful: 'Chỉ định văn phòng thành công',
          sendAllEmailSuccessful: 'Gửi tất cả email thành công',
          blockComplaintSuccessful: 'Khóa khiêu nại thành công',
          markComplaintSuccessful: 'Đánh dấu khiếu nại thành công',
          unblockComplaintSuccessful: 'Mở khóa khiếu nại thành công',
          transferBonusSuccessful: 'Tiền thưởng đã được chuyển thành công',
          syncDataCompleted: 'Đồng bộ hóa dữ liệu đã hoàn tất!',
          signupSuccessful: 'Bạn đã đăng ký tài khoản thành công',
          massUpdateEmployeesSuccessful: 'Cập nhật hàng loạt nhân viên thành công',
          closeComplaintSuccessful: 'Khiếu nại đã được đóng!'
        },
        loading: {
          syncData: 'Đang đồng bộ dữ liệu, chúng tôi sẽ thông báo khi hoàn tất'
        }
      }
    },
    pages: {
      notFound: {
        header: '404: Không tìm thấy trang bạn yêu cầu',
        subtitle:
          'Xin hãy kiểm tra lại đường dẫn của bạn và chắc rằng bạn có quyền truy cập vào trang này'
      },
      approverBonusRequest: {
        all: 'Tất cả',
        to: '1 triệu - 5 triệu',
        to1: '5 triệu - 10 triệu',
        above: 'Trên 10 triệu',
        below: 'Dưới 1 triệu',
        waiting: 'Đang chờ',
        approved: 'Đã duyệt',
        reject: 'Từ chối'
      },
      manageInformation: {
        officeName: 'Tên văn phòng',
        numOfEmployees: 'Số nhân viên',
        numOfSalesTeam: 'Số đội sales',
        city: 'Thành phố',
        address: 'Địa chỉ',
        officeManager: 'Người quản lý văn phòng',
        office: 'Văn phòng',
        teamName: 'Tên team',
        teamLeader: 'Team leader',
        fullName: 'Họ tên',
        Identity: 'CMND/CCCD',
        username: 'Tên đăng nhập',
        email: 'Email',
        manageBy: 'Quản lý bởi',
        role: 'Chức vụ',
        team: 'Team',
        phone: 'Số điện thoại',
        gender: 'Giới tính',
        status: 'Trạng thái',
        bonusStatus: 'Trạng thái bonus',
        from: 'Từ ngày',
        to: 'Đến ngày',
        officeCode: 'Mã văn phòng'
      },
      userPermission: {
        fullName: 'Họ tên',
        role: 'Chức vụ',
        userName: 'Tên tài khoản',
        email: 'Email',
        phone: 'Số điện thoại',
        male: 'Nam',
        female: 'Nữ',
        gender: 'Giới tính',
        national: 'CMND/CCCD'
      },
      manageEmployees: {
        name: 'Tên nhân viên',
        role: 'Chức vụ',
        teamName: 'Tên team',
        office: 'Văn phòng',
        account: 'Tài khoản',
        sendLink: 'Gửi link tạo tài khoản',
        team: 'Team'
      },
      manageTeams: {
        all: 'Tất cả',
        to: '5 đến 10',
        above: 'Trên 10',
        below: 'Dưới 5'
      },
      manageOffice: {
        all: 'Tất cả',
        to: '500 đến 1000',
        above: 'Trên 1000',
        below: 'Dưới 500'
      },
      approverRequestBonus: {
        approverFailure: 'Duyệt bonus không thành công'
      },
      bonusDetails: {
        complaintBonusBlocked: 'Khiếu nại của bonus này đã bị khóa! Khiếu nại sẽ được mở khóa vào ',
        markComplaint: 'Đánh dấu khiếu nại',
        markComplaintPrompt:
          'Bạn có chắc là bạn muốn đánh dấu khiếu nại tài khoản này? Nếu đánh dấu khiếu nại vượt quá 3 lần thì tài khoản này sẽ bị chặn khiếu nại trong 30 ngày.'
      },
      manageBonus: {
        dateRequestSalary: 'Ngày rút lương',
        dateRequestBonus: 'Ngày rút bonus',
        bonusStatistics: 'Thống kê bonus',
        bonusSubcaption: 'Tổng tiền thưởng qua các tháng',
        bonusYaxisname: 'Tổng bonus(vnđ)',
        bonusNumbersuffix: 'Tr',
        ftdStatistics: 'Thống kê FTD',
        ftdSubcaption: 'Tổng ftd qua các tháng',
        ftdYaxiname: 'Tổng ftd(USD)',
        ftdNumbersuffix: 'USD',
        customerStatistics: 'Thống kê khách hàng',
        customerSubcaption: 'Tổng khách hàng qua các tháng',
        customerYaxiname: 'Tổng khách hàng (người)',
        confirmWithdrawalRequest: 'Xác nhận yêu cầu rút tiền',
        confirmWithdrawalContent:
          ' Vui lòng kiểm tra kỹ lưỡng số tiền bạn yêu cầu rút và đảm bảo số tài khoản nhận tiền là chính xác. Hãy phản hồi nếu có sai sót trước khi bấm xác nhận. Nếu sau khi bạn xác nhận rút tiền và phát hiện có sai sót liên quan đến số tiền yêu cầu rút hoặc số tài khoản nhận tiền, chúng tôi sẽ không chịu trách nhiệm.'
      },
      notification: {
        unblocked: 'đã mở khóa khiếu nại',
        requestBonus: 'Yêu cầu duyệt bonus',
        requestSalary: 'Yêu cầu duyệt lương',
        complaintBonus: 'Đã khiếu nại bonus',
        approveBonus: 'Bonus của bạn đã được duyệt',
        rejectBonus: 'Bonus của bạn đã bị từ chối',
        approveSalary: 'Lương của bạn đã được duyệt',
        rejectSalary: 'Lương của bạn đã bị từ chối',
        transferSalary: 'Chuyển tiền lương thành công',
        transferSalaryError: 'Chuyển tiền lương thất bại',
        markComplaints: 'Đánh dấu khiếu nại',
        bannedComplaints: 'Khiếu nại bị khóa',
        bannedComplaintsContent: 'Admin đã khóa chức năng khiếu nại của bạn trong 30 ngày.',
        markComplaintsContent: 'Admin đã đánh dấu khiếu nại của bạn',
        blockedComplaint: 'đã khóa khiếu nại của bonus',
        transfer: 'bonus đã được chuyể tiền',
        content: 'Nội dung',
        from: 'Thông báo từ',
        typeNotifications: 'Loại thông báo',
        createAt: 'Thời gian',
        by: 'bởi',
        typeRequestBonus: 'Yêu cầu duyệt bonus',
        typeReport: 'Khiếu nại',
        typeApprove: 'Bonus đã được duyệt',
        typeReject: 'Bonus bị từ chối',
        typeTransfer: 'Chuyển tiền thưởng bonus',
        notNotification: 'Không có thông báo',
        seeAll: 'Xem tất cả'
      },
      signup: {
        loginInfo: 'Thông tin đăng nhập',
        personalInfo: 'Thông tin cá nhân',
        bankAccountInfo: 'Thông tin tài khoản ngân hàng',
        addressWallet: 'Địa chỉ ví (USDT TRC20)'
      },
      settings: {
        qrCode: 'Địa chỉ ví(USDT) - QR Code'
      },
      userInformations: {
        chooseGender: 'Chọn giới tính',
        chooseBank: 'Chọn ngân hàng'
      },
      complaints: {
        createComplaints: 'Tạo khiêu nại',
        writeComplaint: 'Viết khiếu nại',
        answerComplaint: 'Trả lời khiếu nại',
        bonusOrSalary: 'Bonus hoặc lương',
        technical: 'Lỗi kỹ thuật',
        typeComplaint: 'Loại khiếu nại',
        dateBonus: 'Bonus tháng',
        dateComplaint: 'Ngày khiếu nại',
        content: 'Nội dung',
        complaintDetail: 'Chi tiết khiếu nại',
        closeComplaint: 'Đóng khiếu nại',
        complaintAboutBonus: 'Khiếu nại về bonus hoặc lương',
        complaintAboutTechnical: 'Khiêu nại về lỗi kỹ thuật',
        closeComplaintPrompt:
          'Bạn có chắc là muốn đóng khiếu nại? Hành động này không thể hoàn tác!',
        closeComplaintSuccessful: 'Khiếu nại đã được đóng!',
        complaintBlocked: 'Khiếu nại này đã bị khóa! Khiếu nại sẽ được mở khóa vào ',
        bonusOf: 'Bonus của '
      }
    },
    notAvailable: 'Chưa cập nhật',
    notAnswered: 'Chưa trả lời',
    answered: 'Đã trả lời',
    read: 'Đã đọc',
    notClose: 'Đang mở',
    closed: 'Đã đóng',
    markAsRead: 'Đánh dấu đọc',
    markAsReadSuccessfully: 'Đánh dấu đã đọc thành công',
    rejectContentBonus: 'Vui lòng nhập lý do bạn từ chối bonus!',
    noData: 'Không có dữ liệu!',
    tryAgain: 'Thử lại',
    acceptWithdrawalBonus: 'Xác nhận rút bonus',
    acceptWithdrawalSalary: 'Xác nhận rút lương',
    acceptWithdrawalSalaryBonus: 'Xác nhận rút lương và bonus',
    cannotWithdrawalBonus: 'Không thể rút bonus',
    cannotComplaintBonus: 'Không thể khiếu nại bonus',
    blockComplaint: 'Khóa khiếu nại',
    unBlockComplaint: 'Mở khóa khiếu nại'
  }
}

export default vi
