import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import AuthGuard from 'src/components/common/AuthGuard'
import DashboardLayout from 'src/components/common/DashboardLayout'
import LoadingScreen from 'src/components/common/LoadingScreen'
import MainLayout from 'src/components/common/MainLayout'

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// course
const CourseList = Loadable(lazy(() => import('./pages/course/course-list')))
const CourseCreate = Loadable(lazy(() => import('./pages/course/course-create')))
const CourseEdit = Loadable(lazy(() => import('./pages/course/course-update')))

// course level
const CourseLevelList = Loadable(lazy(() => import('./pages/course-level/course-level-list')))
const CourseLevelCreate = Loadable(lazy(() => import('./pages/course-level/course-level-create')))
const CourseLevelUpdate = Loadable(lazy(() => import('./pages/course-level/course-level-update')))

// lesson
const LessonList = Loadable(lazy(() => import('./pages/lesson/lesson-list')))
const LessonCreate = Loadable(lazy(() => import('./pages/lesson/lesson-create')))
const LessonEdit = Loadable(lazy(() => import('./pages/lesson/lesson-update')))

// lesson category
const LessonCategoryList = Loadable(
  lazy(() => import('./pages/lesson-category/lesson-category-list'))
)
const LessonCategoryCreate = Loadable(
  lazy(() => import('./pages/lesson-category/lesson-category-create'))
)
const LessonCategoryUpdate = Loadable(
  lazy(() => import('./pages/lesson-category/lesson-category-update'))
)

// course category
const CourseCategoryList = Loadable(
  lazy(() => import('./pages/course-category/course-category-list'))
)
const CourseCategoryCreate = Loadable(
  lazy(() => import('./pages/course-category/course-category-create'))
)
const CourseCategoryUpdate = Loadable(
  lazy(() => import('./pages/course-category/course-category-update'))
)

// goal
const GoalList = Loadable(lazy(() => import('./pages/goal/goal-list')))
const GoalCreate = Loadable(lazy(() => import('./pages/goal/goal-create')))
const GoalEdit = Loadable(lazy(() => import('./pages/goal/goal-update')))

// news
const NewsArticleList = Loadable(lazy(() => import('./pages/news-article/news-article-list')))
const NewsArticleCreate = Loadable(lazy(() => import('./pages/news-article/news-article-create')))
const NewsArticleEdit = Loadable(lazy(() => import('./pages/news-article/news-article-update')))

const NewsCategoryList = Loadable(lazy(() => import('./pages/news-category/news-category-list')))
const NewsCategoryCreate = Loadable(
  lazy(() => import('./pages/news-category/news-category-create'))
)
const NewsCategoryEdit = Loadable(lazy(() => import('./pages/news-category/news-category-update')))

// blog
const BlogList = Loadable(lazy(() => import('./pages/blog/blog-list')))
const BlogCreate = Loadable(lazy(() => import('./pages/blog/blog-create')))
const BlogEdit = Loadable(lazy(() => import('./pages/blog/blog-update')))

const BlogCategoryList = Loadable(lazy(() => import('./pages/blog-category/blog-category-list')))
const BlogCategoryCreate = Loadable(
  lazy(() => import('./pages/blog-category/blog-category-create'))
)
const BlogCategoryEdit = Loadable(lazy(() => import('./pages/blog-category/blog-category-update')))

// webinar-scheduler
const WebinarSchedulerList = Loadable(
  lazy(() => import('./pages/webinar-scheduler/webinar-scheduler-list'))
)
const WebinarScheduleCreate = Loadable(
  lazy(() => import('./pages/webinar-scheduler/webinar-scheduler-create'))
)
const WebinarScheduleEdit = Loadable(
  lazy(() => import('./pages/webinar-scheduler/webinar-scheduler-update'))
)
const WebinarUploaderList = Loadable(
  lazy(() => import('./pages/webinar-uploader/webinar-uploader-list'))
)
const UserRequestList = Loadable(lazy(() => import('./pages/user-request/user-request-list')))

// webinar-report
const WebinarReportList = Loadable(lazy(() => import('./pages/webinar-report/webinar-report-list')))
const WebinarReportDetail = Loadable(
  lazy(() => import('./pages/webinar-report/webinar-report-detail'))
)

// webinar-manager
const WebinarManagerList = Loadable(
  lazy(() => import('./pages/webinar-manager/WebinarManagerList'))
)
const WebinarManagerDetail = Loadable(
  lazy(() => import('./pages/webinar-manager/webinar-manager-detail'))
)
// webinar-review
const WebinarReviewList = Loadable(lazy(() => import('./pages/webinar-review/webinar-review-list')))
const WebinarReviewCreate = Loadable(
  lazy(() => import('./pages/webinar-review/webinar-review-create'))
)
const WebinarReviewUpdate = Loadable(
  lazy(() => import('./pages/webinar-review/webinar-review-update'))
)

// notification manager
const NotificationList = Loadable(
  lazy(() => import('./pages/notification-manager/notification-manager-list'))
)
const NotificationCreate = Loadable(
  lazy(() => import('./pages/notification-manager/notification-manager-create'))
)

// magazine
const MagazineList = Loadable(lazy(() => import('./pages/magazine/magazine-list')))
const MagazineCreate = Loadable(lazy(() => import('./pages/magazine/magazine-create')))
const MagazineEdit = Loadable(lazy(() => import('./pages/magazine/magazine-update')))

// magazine review
const MagazineReviewList = Loadable(
  lazy(() => import('./pages/magazine-review/magazine-review-list'))
)
const MagazineReviewCreate = Loadable(
  lazy(() => import('./pages/magazine-review/magazine-review-create'))
)
const MagazineReviewUpdate = Loadable(
  lazy(() => import('./pages/magazine-review/magazine-review-update'))
)

// exchange
const ExchangeCreate = Loadable(lazy(() => import('./pages/exchange/exchange-create')))
const ExchangeList = Loadable(lazy(() => import('./pages/exchange/exchange-list')))
const ExchangeEdit = Loadable(lazy(() => import('./pages/exchange/exchange-update')))

// exchange review
const ExchangeReviewCreate = Loadable(
  lazy(() => import('./pages/exchange-review/exchange-review-create'))
)
const ExchangeReviewList = Loadable(
  lazy(() => import('./pages/exchange-review/exchange-review-list'))
)
const ExchangeReviewUpdate = Loadable(
  lazy(() => import('./pages/exchange-review/exchange-review-update'))
)

// const ReviewByExchangeId = Loadable(
//   lazy(() => import('./pages/exchange-review/review-by-exchangeId'))
// )

// document
const CourseDocumentList = Loadable(
  lazy(() => import('./pages/course-document/course-document-list'))
)
const CourseDocumentEdit = Loadable(
  lazy(() => import('./pages/course-document/course-document-update'))
)
const CourseDocumentCreate = Loadable(
  lazy(() => import('./pages/course-document/course-document-create'))
)

// user-manager
const UserManagerList = Loadable(lazy(() => import('./pages/user-manager/user-manager-list')))
const UserManagerCreate = Loadable(lazy(() => import('./pages/user-manager/user-manager-create')))
const UserManagerEdit = Loadable(lazy(() => import('./pages/user-manager/user-manager-update')))
const UserManagerDetail = Loadable(
  lazy(() => import('./pages/user-manager/user-manager-detail-page'))
)

// ads-manager
const AdsManagerList = Loadable(lazy(() => import('./pages/ads-manager/ads-manager-list')))
const AdsManagerCreate = Loadable(lazy(() => import('./pages/ads-manager/ads-manager-create')))
const AdsManagerUpdate = Loadable(lazy(() => import('./pages/ads-manager/ads-manager-update')))

const Personal = Loadable(lazy(() => import('./pages/Personal')))
const DefaultRedirect = Loadable(lazy(() => import('./pages/DefaultRedirect')))
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')))
const NotFound = Loadable(lazy(() => import('./pages/NotFound')))
const ServerError = Loadable(lazy(() => import('./pages/ServerError')))

// playlist
const Playlist = Loadable(lazy(() => import('./pages/playlist/playlist-list')))
const PlaylistCreate = Loadable(lazy(() => import('./pages/playlist/playlist-create')))
const PlaylistUpdate = Loadable(lazy(() => import('./pages/playlist/playlist-update')))

// topic
const TopicList = Loadable(lazy(() => import('./pages/topic/topic-list')))
const TopicCreate = Loadable(lazy(() => import('./pages/topic/topic-create')))
const TopicUpdate = Loadable(lazy(() => import('./pages/topic/topic-update')))

// playlist type
const PlaylistType = Loadable(lazy(() => import('./pages/playlist-type/type-list')))
const PlaylistTypeCreate = Loadable(lazy(() => import('./pages/playlist-type/type-create')))
const PlaylistTypeUpdate = Loadable(lazy(() => import('./pages/playlist-type/type-update')))

// learning-path
const LearningPathList = Loadable(lazy(() => import('./pages/learning-path/learning-path-table')))
const LearningPathCreate = Loadable(
  lazy(() => import('./pages/learning-path/learning-path-create'))
)
const LearningPathUpdate = Loadable(
  lazy(() => import('./pages/learning-path/learning-path-update'))
)

// seeder
const SeederList = Loadable(lazy(() => import('./pages/seeder/seeder-table')))
const SeederCreate = Loadable(lazy(() => import('./pages/seeder/seeder-create')))
const SeederUpdate = Loadable(lazy(() => import('./pages/seeder/seeder-update')))

// lesson-comment
const LessonCommentList = Loadable(lazy(() => import('./pages/lesson-comment/lesson-comment-list')))
const LessonCommentCreate = Loadable(
  lazy(() => import('./pages/lesson-comment/lesson-comment-create'))
)
const LessonCommentUpdate = Loadable(
  lazy(() => import('./pages/lesson-comment/lesson-comment-update'))
)

// lesson-review
const LessonReviewList = Loadable(lazy(() => import('./pages/lesson-review/lesson-review-list')))
const LessonReviewCreate = Loadable(
  lazy(() => import('./pages/lesson-review/lesson-review-create'))
)
const LessonReviewUpdate = Loadable(
  lazy(() => import('./pages/lesson-review/lesson-review-update'))
)

// lesson-review
const CourseRatingList = Loadable(lazy(() => import('./pages/course-rating/course-rating-list')))
const CourseRatingCreate = Loadable(
  lazy(() => import('./pages/course-rating/course-rating-create'))
)
const CourseRatingUpdate = Loadable(
  lazy(() => import('./pages/course-rating/course-rating-update'))
)

// magazine-comment
const MagazineCommentList = Loadable(
  lazy(() => import('./pages/magazine-comment/magazine-comment-list'))
)
const MagazineCommentCreate = Loadable(
  lazy(() => import('./pages/magazine-comment/magazine-comment-create'))
)
const MagazineCommentUpdate = Loadable(
  lazy(() => import('./pages/magazine-comment/magazine-comment-update'))
)

// contact
const ContactList = Loadable(lazy(() => import('./pages/contact/contact-list')))

// redirect
const RedirectList = Loadable(lazy(() => import('./pages/redirect/redirect-list')))
const RedirectCreate = Loadable(lazy(() => import('./pages/redirect/redirect-create')))
const RedirectUpdate = Loadable(lazy(() => import('./pages/redirect/redirect-update')))

// insight
const InsightList = Loadable(lazy(() => import('./pages/insight/insight-list')))
const InsightCreateUpdate = Loadable(lazy(() => import('./pages/insight/insight-create-update')))

// Knowledge
const KnowledgeList = Loadable(lazy(() => import('./pages/knowledge/knowledge-list')))
const KnowledgeCreateUpdate = Loadable(
  lazy(() => import('./pages/knowledge/knowledge-create-update'))
)

// series
const SeriesList = Loadable(lazy(() => import('./pages/series/series-list')))
const SeriesCreateUpdate = Loadable(lazy(() => import('./pages/series/series-create-update')))

// lecture
const LectureList = Loadable(lazy(() => import('./pages/lecture/lecture-list')))
const LectureCreateUpdate = Loadable(lazy(() => import('./pages/lecture/lecture-create-update')))

// lecturer
const LecturerList = Loadable(lazy(() => import('./pages/lecturer/lecturer-list')))
const LecturerCreateUpdate = Loadable(lazy(() => import('./pages/lecturer/lecturer-create-update')))

const routes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <DefaultRedirect />
      },
      {
        path: 'learning-path',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <LearningPathList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <LearningPathCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <LearningPathUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'course',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <CourseList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <CourseCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <CourseEdit />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'playlist',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <Playlist />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <PlaylistCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <PlaylistUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'topic',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <TopicList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <TopicCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <TopicUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'lesson-classify',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <PlaylistType />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <PlaylistTypeCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <PlaylistTypeUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'course-level',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <CourseLevelList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <CourseLevelCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <CourseLevelUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'lesson',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <LessonList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <LessonCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <LessonEdit />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'lesson-category',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <LessonCategoryList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <LessonCategoryCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <LessonCategoryUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'course-category',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <CourseCategoryList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <CourseCategoryCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <CourseCategoryUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'goal',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <GoalList />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <GoalEdit />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <GoalCreate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'course-document',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <CourseDocumentList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <CourseDocumentCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <CourseDocumentEdit />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'news-article',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <NewsArticleList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <NewsArticleCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <NewsArticleEdit />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'news-category',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <NewsCategoryList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <NewsCategoryCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <NewsCategoryEdit />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'blog',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <BlogList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <BlogCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <BlogEdit />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'blog-category',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <BlogCategoryList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <BlogCategoryCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <BlogCategoryEdit />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'magazine',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <MagazineList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <MagazineCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <MagazineEdit />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'magazine-review',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <MagazineReviewList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <MagazineReviewCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <MagazineReviewUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'magazine-comment',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <MagazineCommentList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <MagazineCommentCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <MagazineCommentUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'exchange',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <ExchangeList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <ExchangeCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <ExchangeEdit />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'exchange-review',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <ExchangeReviewList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <ExchangeReviewCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <ExchangeReviewUpdate />,
            roles: ['administrator']
          }
          // {
          //   path: 'review-by-exchangeId',
          //   element: <ReviewByExchangeId />,
          //   roles: ['administrator']
          // }
        ]
      },
      {
        path: 'webinar-report',
        roles: ['administrator', 'author'],
        children: [
          {
            path: '',
            element: <WebinarReportList />,
            roles: ['administrator', 'author']
          },
          {
            path: ':id/detail',
            element: <WebinarReportDetail />,
            roles: ['administrator', 'author']
          }
        ]
      },
      {
        path: 'webinar-scheduler',
        roles: ['administrator', 'author'],
        children: [
          {
            path: '',
            element: <WebinarSchedulerList />,
            roles: ['administrator', 'author']
          },
          {
            path: 'create',
            element: <WebinarScheduleCreate />,
            roles: ['administrator', 'author']
          },
          {
            path: ':id/edit',
            element: <WebinarScheduleEdit />,
            roles: ['administrator', 'author']
          },
          {
            path: ':id/user-request',
            element: <UserRequestList />,
            roles: ['administrator', 'author']
          }
        ]
      },
      {
        path: 'webinar-manager',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <WebinarManagerList />,
            roles: ['administrator']
          },
          {
            path: ':id/detail',
            element: <WebinarManagerDetail />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'webinar-review',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <WebinarReviewList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <WebinarReviewCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <WebinarReviewUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'webinar-uploader',
        roles: ['administrator', 'author'],
        children: [
          {
            path: '',
            element: <WebinarUploaderList />,
            roles: ['administrator', 'author']
          }
        ]
      },
      {
        path: 'user-manager',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <UserManagerList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <UserManagerCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <UserManagerEdit />,
            roles: ['administrator']
          },
          {
            path: ':id/detail',
            element: <UserManagerDetail />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'seeder',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <SeederList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <SeederCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <SeederUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'ads-manager',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <AdsManagerList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <AdsManagerCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <AdsManagerUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'notification-manager',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <NotificationList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <NotificationCreate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'lesson-comment',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <LessonCommentList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <LessonCommentCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <LessonCommentUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'lesson-review',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <LessonReviewList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <LessonReviewCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <LessonReviewUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'course-rating',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <CourseRatingList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <CourseRatingCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <CourseRatingUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'contact',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <ContactList />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'personal',
        element: <Personal />,
        roles: ['administrator', 'author']
      },
      {
        path: 'redirect',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <RedirectList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <RedirectCreate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <RedirectUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'insight',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <InsightList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <InsightCreateUpdate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <InsightCreateUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'knowledge',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <KnowledgeList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <KnowledgeCreateUpdate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <KnowledgeCreateUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'series',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <SeriesList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <SeriesCreateUpdate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <SeriesCreateUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'lecture',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <LectureList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <LectureCreateUpdate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <LectureCreateUpdate />,
            roles: ['administrator']
          }
        ]
      },
      {
        path: 'lecturer',
        roles: ['administrator'],
        children: [
          {
            path: '',
            element: <LecturerList />,
            roles: ['administrator']
          },
          {
            path: 'create',
            element: <LecturerCreateUpdate />,
            roles: ['administrator']
          },
          {
            path: ':id/edit',
            element: <LecturerCreateUpdate />,
            roles: ['administrator']
          }
        ]
      }
    ]
  },

  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/" />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]

export default routes
