import { createContext, useEffect, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'
import { useKeycloak } from '@react-keycloak/web'
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import * as jwt from 'jsonwebtoken'
import { ApolloClientContext } from '../index'

const createAuthenticatedClient = (token) => {
  const httpLink = createHttpLink({
    fetch,
    uri: process.env.REACT_APP_HASURA_URL
  })

  const authLink = setContext((_, { headers }) => {
    let role = ''
    if (token) {
      const decodedToken = jwt.decode(token)
      role = decodedToken?.['https://hasura.io/jwt/claims']?.['x-hasura-allowed-roles']?.[0]
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'X-Hasura-Role': role
      }
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) graphQLErrors.forEach(async ({ message, locations, path }) => {})
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  return new ApolloClient({
    link: from([authLink, httpLink, errorLink]),
    cache: new InMemoryCache(),
    defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } }
  })
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  role: null
}

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, role } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      role
    }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    role: null
  })
}

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state
const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  // eslint-disable-next-line no-undef
  logout: () => Promise.resolve()
})

export const AuthProvider = (props) => {
  const { keycloak, initialized } = useKeycloak()
  const [, setApolloClient] = useContext(ApolloClientContext)
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const role = keycloak?.resourceAccess?.['hasura-keycloak-connector']?.roles[0]

    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: keycloak.authenticated,
        user: keycloak.idTokenParsed,
        role
      }
    })

    // if (keycloak.authenticated) {
    const client = createAuthenticatedClient(keycloak.token)
    setApolloClient(client)
    // }
  }, [initialized])

  const logout = async () => {
    keycloak.logout()
    // setSession(null);
    dispatch({ type: 'LOGOUT' })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthContext
