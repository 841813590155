import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import Footer from './Footer'
import DashboardNavbar from './DashboardNavbar'

interface IProps {
  children?: ReactNode
}

const MainLayout = ({ children }: IProps) => (
  <Box sx={{ bgcolor: 'background.default', minHeight: '100%' }}>
    <DashboardNavbar />
    {children || <Outlet />}
    <Footer />
  </Box>
)

export default MainLayout
