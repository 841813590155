import { Box, Toolbar } from '@mui/material'
import { useTheme } from '@mui/styles'
import AccountPopover from './AccountPopover'

const DashboardNavbar = (props) => {
  const { ...other } = props
  const theme = useTheme()

  return (
    <Box
      sx={{
        top: 0,
        width: '100%',
        ...(theme.palette.mode === 'light' && {
          backgroundColor: '#ffffff',
          borderBottom: `1px solid ${theme.palette.divider}`
        }),
        ...(theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`
        }),
        zIndex: theme.zIndex.drawer + 100
      }}
      {...other}
      position="sticky"
    >
      <Toolbar
        sx={{ width: '100%', minHeight: 64, display: 'flex', justifyContent: 'space-between' }}
      >
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <AccountPopover />
      </Toolbar>
    </Box>
  )
}

export default DashboardNavbar
