/* eslint-disable */
import PropTypes from 'prop-types'
import { matchPath } from 'react-router-dom'
import { List, ListSubheader, Stack, Typography } from '@mui/material'
import NavItem from './NavItem'
import { amber, blueGrey, cyan, green, lightGreen } from '@mui/material/colors'

const renderNavItems = ({ depth = 0, items, pathname }) => (
  <List disablePadding>
    <Stack spacing={1.25}>
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth
          }),
        []
      )}
    </Stack>
  </List>
)

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const key = `${item.title}-${depth}`
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true
        },
        pathname
      )
    : false

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false
          },
          pathname
        )
      : false

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />
    )
  }

  return acc
}

const NavSection = (props) => {
  const { items, pathname, title, ...other } = props

  return (
    <List
      subheader={
        <ListSubheader disableGutters disableSticky>
          <Typography
            ml={1}
            mb={0}
            fontWeight={700}
            color={cyan[300]}
            variant="caption"
            sx={{ textTransform: 'uppercase' }}
          >
            {title}
          </Typography>
        </ListSubheader>
      }
      {...other}
    >
      <Stack spacing={1.5}>
        {renderNavItems({
          items,
          pathname
        })}
      </Stack>
    </List>
  )
}

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
}

export default NavSection
