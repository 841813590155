import { ElementType, ReactNode, useMemo, useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { Box, Button, Collapse, ListItem, Typography } from '@mui/material'
import ChevronDownIcon from '../../icons/ChevronDown'
import ChevronRightIcon from '../../icons/ChevronRight'

type Props = {
  active?: boolean
  children?: ReactNode
  depth: number
  icon: ElementType<any>
  info: any
  open?: boolean
  path: string
  title: string
}

const NavItem = ({
  active = false,
  children,
  depth,
  icon,
  info,
  open: openProp = false,
  path,
  title,
  ...other
}: Props) => {
  const [open, setOpen] = useState(openProp)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const iconColor = useMemo(() => (active ? 'common.white' : 'common.white'), [active])
  const paddingLeft = useMemo(() => (depth > 0 ? 8 + 8 * depth : 16), [depth])

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />
          }
          onClick={handleToggle}
          startIcon={<Box component={icon} size="small" />}
          sx={{
            color: 'common.white',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            py: 1.25,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            mb: 1
          }}
          variant="text"
        >
          <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    )
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      <Button
        component={path && RouterLink}
        startIcon={<Box component={icon} size="small" sx={{ color: iconColor }} />}
        sx={{
          color: 'common.white',
          fontWeight: 600,
          justifyContent: 'flex-start',
          textAlign: 'left',
          pl: `${paddingLeft}px`,
          py: 1.25,
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'common.white',
            fontWeight: 700,
            backgroundColor: '#1fd1f9',
            backgroundImage: 'linear-gradient(90deg, #9c9fff 0%, #6771e6 100%)',

            '&:hover': {
              backgroundColor: 'primary.dark'
            }
          })
        }}
        variant="text"
        to={path}
      >
        <Typography variant="subtitle2" sx={{ flexGrow: 1, fontWeight: active ? 700 : 500 }}>
          {title}
        </Typography>
        {info}
      </Button>
    </ListItem>
  )
}

export default NavItem
