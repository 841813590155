const en = {
  translation: {
    dashboard: 'Dashboard ',
    account: 'Account',
    manageBonus: 'Manage Bonus',
    manageSalaryBonus: 'Manage Salary & Bonus',
    salesDocuments: 'Sales Documents',
    manageTeamBonus: 'Manage Team Bonus',
    trainingDocuments: 'Training Documents',
    support: 'Support',
    manageInformation: 'Manage Information',
    userInformation: 'User Information',
    assignOffice: 'Assign Office',
    officesInformation: 'Offices Information',
    teamsInformation: 'Teams Information',
    employeesInformation: 'Employees Information',
    userPermission: 'User Permission',
    bonusStatistics: 'Bonus Statistics',
    incomeStatistics: 'Income Statistics',
    totalIncome: 'Total Income',
    approveBonusRequests: 'Approve Bonus Requests',
    approverSalaryRequests: 'Approve Salary Requests',
    approverSalary: 'Approve Salary',
    rejectedSalary: 'Reject Salary',
    responseComplaints: 'Response Complaints',
    bonusDetails: 'Bonus Details',
    salaryBonusDetails: 'Salary & Bonus Details',
    manageNotifications: 'Notifications',
    totalBonusThisMonth: 'Total Bonus This Month',
    totalFtdThisMonth: 'Total FTD This Month',
    totalCustomers: 'Total Customers',
    totalPersonalBonus: 'Total Personal Bonus',
    totalTeamBonus: 'Total Team Bonus',
    month: 'Month',
    totalBonus: 'Total Bonus',
    totalFtd: 'Total FTD',
    bonusTicket: 'Bonus Ticket',
    status: 'Status',
    bonusStatus: 'Bonus Status',
    salaryBonus: 'Salary Status',
    withdrawBonus: 'Withdraw Bonus',
    withdrawSalary: 'Withdraw Salary',
    withdraw: 'Withdraw',
    withdrawSalaryBonus: 'Withdraw Salary/Bonus',
    withdrawBoth: 'withdraw Both',
    complain: 'Complaints',
    details: 'Details',
    viewBonusTicket: 'View Bonus Ticket',
    folderName: 'File Name',
    addedBy: 'Added By',
    lastModified: 'Last Modified',
    Documents: 'Documents',
    viewDocument: 'View Document',
    removePicture: 'Remove Picture',
    updatePicture: 'Update Picture',
    addPicture: 'Add Picture',
    profile: 'Profile',
    identity: 'National ID',
    frontIdentity: 'Front National ID',
    backIdentity: 'Back National ID',
    changeCover: ' Change Cover',
    changePassword: 'Change Password',
    usernameLogin: 'Used to login',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    passwordConfirmation: 'Password confirmation',
    signup: 'Register an account',
    createUser: 'Create User',
    password: 'Password',
    rowsPerPage: 'Rows per page:',
    bonusHistory: 'Bonus History',
    bonusSalaryBonus: 'Salary & Bonus History',
    fullName: 'Full Name',
    office: 'Office',
    gender: 'Gender',
    phone: 'Phone',
    offices: 'Offices',
    userNotOffice: 'No Office',
    userNotTeam: 'No Team',
    cancel: 'Cancel',
    male: 'Male',
    approverBy: 'Approved By',
    exportFile: 'Export File',
    exportPage: 'Export by filter',
    exportAll: 'Export All',
    salesTeam: 'Team sales',
    viewDetails: 'View Details',
    nameStaff: 'Name Staff',
    errorOccurred: 'An error has occurred!',
    searchPlaceholder: 'Search...',
    roles: 'Roles',
    username: 'Username',
    active: 'Active',
    activeBonus: 'Bonus Status',
    activeSalary: 'Salary Status',
    enable: 'Active',
    disable: 'Inactive',
    update: 'Update',
    created: 'Created',
    notCreated: 'Not Created',
    name: 'Name',
    deleteUser: 'Delete User',
    deleteTeam: 'Delete Team',
    deleteOffice: 'Delete Office',
    deletePrompt: 'Are you sure you want to delete this user? This action cannot be undone!',
    deletePromptTeam: 'Are you sure you want to delete this team? This action cannot be undone!',
    restoreUser: 'The user already exists in the system, do you want to restore?',
    deletePromptOffice:
      'Are you sure you want to delete this office? This action cannot be undone!',
    cannotRequestBonusPrompt: 'You have to finish your profile before withdrawing this bonus!',
    cannotComplaintBonusPrompt: 'You must update all profile before complaint bonus!',
    blockComplaintPrompt:
      'Are you sure you want to block this complaint? This will be effective for 7 days.',
    unBlockComplaintPrompt: 'Are you sure you want to unblock the complaint of this bonus!',
    createNewUserPrompt:
      'Creating a new one will delete this user old data and set up a new account',
    restoreUserPrompt: 'Restore will retain this account data and usage history',
    acceptAllBonus: 'You must approve all selected bonuses? This action cannot be undone!',
    acceptAllSalary: 'You must approve all selected salary? This action cannot be undone!',
    confirmEmail: 'Please confirm your email before logging in!',
    sentEmailTo: 'We have sent notice to',
    checkEmail: 'Please check your email!',
    signUp: '',
    login: 'Login',
    rejectBonusPrompt: 'Are you sure you want to decline this bonus? This action is not complete!',
    rejectBonusAllPrompt:
      'Are you sure you want to decline the selected bonuses? This action is not complete!',
    requestId: 'Request ID',
    requestedDate: 'Requested Date',
    withdrawDateSalary: 'Salary withdrawal date',
    withdrawDateBonus: 'Bonus withdrawal date',
    createdAt: 'Created At',
    monthBonus: 'Month Bonus',
    monthSalary: 'Month Salary',
    bonusAmount: 'Bonus Amount',
    amount: 'Amount',
    approve: 'Approve',
    approveAll: 'Approve All',
    rejectAll: 'Reject All',
    resendAllEmail: 'Resend All',
    accept: 'Accept',
    pending: 'Pending',
    reject: 'Rejected',
    waiting: 'Waiting',
    hasApproved: 'Approved',
    hasTransfer: 'Transferred',
    transferFail: 'Money transfer failure',
    approveBonus: 'Approve Bonus',
    approveSalary: 'Approve Salary',
    approveAllBonus: 'Approve All Bonus',
    approveAllSalary: 'Approve All Salary',
    transferSalaryType: 'Transfer salary successfully',
    transferSalaryError: 'Transfer salary unsuccessfully',
    rejectBonus: 'Reject Bonus',
    rejectSalary: 'Reject Salary',
    bonusApprovedSuccessfully: 'Bonus Approved Successfully!',
    salaryApprovedSuccessfully: 'Salary Approved Successfully!',
    incomePeriod: 'Income Period',
    totalWithdrawableMoney: 'Total Withdrawable Money',
    content: 'Content',
    email: 'Email',
    complainBonus: 'Please provide a reason why you reject this bonus!',
    complainSalary: 'Please provide a reason why you reject this salary!',
    complainedAccount: 'Complained Personal',
    complainedDate: 'Complained Date',
    complaintsDetails: 'Complaint Details',
    months: 'Month',
    teams: 'Teams',
    selectImage: 'Select Image',
    dropImage: 'Drop your image here or browse image on your machine.',
    sales: 'Sales',
    markAsReadSure: 'Are you sure you want to mark this as read?',
    bonusApprovedFailed: 'Failed to approve bonus!',
    salaryApprovedFailed: 'Failed to approve salary!',
    customer: 'Customer',
    bonus: 'Bonus',
    userId: 'User ID',
    bank: 'Bank',
    numberBank: 'Personal number',
    accountHolder: 'Personal holder',
    approvedBonusNoti: 'has approved your bonus',
    approvedSalaryNoti: 'has approved your salary',
    rejectedBonusNoti: 'has rejected your bonus',
    rejectedSalaryNoti: 'has rejected your salary',
    requestSalaryNoti: 'has requested salary',
    transferSalary: 'has successfully transferred the salary to you ',
    transferError: 'has unsuccessfully transferred the salary to you ',
    complaintBonusNoti: 'has complained about bonus',
    transferredBonusNoti: 'has transferred bonus to your account',
    requestBonusNoti: 'has requested bonus',
    bonusForSM: 'Bonus for sales manager',
    bonusForManager: 'Bonus for manager',
    salary: 'Salary',
    totalBonusSalary: 'Total Bonus & Salary',
    downloadTemplate: 'Download Template',
    noOption: 'No Option',
    officeReceived: 'Offices get bonus',
    freelanceStaff: 'Freelance Staff',
    consultingCollaborators: 'Consulting Collaborators',
    modals: {
      titles: {
        createOffice: 'Create Office',
        editOffice: 'Edit Office',
        createTeam: 'Create Team',
        editTeam: 'Edit Team',
        createEmployee: 'Create Employee',
        editEmployee: 'Edit Employee',
        editUser: 'Edit User',
        createUser: 'Create User',
        resendEmail: 'Resend Email',
        resendAllEmail: 'Resend All Email',
        employeeDetails: 'Employee Details',
        createComplaint: 'Create Complaints',
        uploadEmployees: 'Mass Upload Employees',
        answerComplaint: 'Answer Complaints',
        upLoadTransferBonus: 'Transfer bonus',
        upLoadTransferSalary: 'Transfer salary',
        changeEmployeesInfo: 'Change employee information',
        confirmInfoMerchant: 'Confirm information'
      },
      subtitles: {
        resendEmail:
          'You can resend an email anytime if your teammate had any problem with account creation.',
        createComplaint: 'Do you need any help or question about this bonus?',
        resendAllEmail:
          'You can resend all email your selected any time if your teammate had any problem with account creation.'
      },
      buttons: {
        cancel: 'Cancel',
        confirm: 'Confirm',
        createNew: 'Create New',
        Restore: 'Restore',
        continueSend: 'Continue sending',
        paymentMethod: 'Update payment method',
        goBack: 'Go back'
      }
    },
    buttons: {
      createOffice: 'Create Office',
      createTeam: 'Create Team',
      createEmployee: 'Create Employee',
      exportFile: 'Export File',
      createUser: 'Create User',
      logout: 'Log Out',
      backToDashboard: 'Back To Dashboard',
      send: 'Send',
      blockComplaint: 'Block Complaint',
      unBlockComplaint: 'Unblock Complaint',
      writeComplaint: 'Write Complaint',
      importEmployees: 'Mass Upload Employees',
      massUpdate: 'Mass Update',
      answer: 'Answer',
      importTransfersBonus: 'Transfers Bonus',
      importTransfersSalary: 'Transfers Salary',
      refresh: 'Refresh',
      syncData: 'Sync Data',
      createComplaint: 'Create Complaint',
      closeComplaint: 'Close Complaints'
    },
    inputs: {
      placeholders: {
        searchBoxPlaceholder: 'Search...'
      },
      errorTexts: {
        required: 'This field is required!',
        mustChoose: 'Please choose one!',
        mustBeAnumber: 'Must be a number!',
        emailInvalidate: 'Must be a valid email!',
        phoneInvalidate: 'Must be a valid phone!',
        userNameInvalidate: 'Username must only include numbers and letters!',
        password: 'Must be at least 8 characters!',
        confirmPassword: 'Passwords must match!',
        fullNameCapitalize: 'Full name must capitalize first letter!',
        onlyNumber: 'Only include numbers!',
        spaces: 'Please do not enter spaces!'
      }
    },
    tables: {
      labelRowsPerPage: 'Rows per page:',
      labelDisplayedRows: {
        of: 'of',
        moreThan: 'more than'
      }
    },
    snackbars: {
      messages: {
        error: {
          createError: 'Create unsuccessfully!',
          editedError: 'Edited unsuccessfully!',
          emailIsExit: 'Email is already exists!',
          deleteError: 'Failed to delete!',
          complaintError: 'Failed to complain!',
          rejectBonusError: 'Failed to reject bonus!',
          rejectSalaryError: 'Failed to reject salary!',
          requestBonus: 'Requests withdraw bonus unsuccessfully!',
          requestSalary: 'Requests withdraw salary unsuccessfully!',
          removeAvatarError: 'Remove avatar unsuccessfully!',
          assignOfficeError: 'Assign office unsuccessfully!',
          bonusBlocked: 'Complain of this bonus has been blocked by admin!',
          sendAllEmailError: 'Send all email unsuccessfully!',
          failedToTransferBonus: 'Bonus was failed to transfer!',
          massUpdateEmployeesError: 'Mass update employees unsuccessfully!',
          markComplainError: 'Mark complaints unsuccessfully!',
          closeComplaintError: 'Complaint closure unsuccessfully!'
        },
        success: {
          createdSuccessful: 'Created successfully!',
          editedSuccessful: 'Edited successfully!',
          restoreSuccessful: 'Recovered successfully!',
          deleteSuccessful: 'Deleted successfully!',
          complainedSuccessful: 'Complained successfully!',
          requestBonus: 'Requested withdraw bonus successfully!',
          requestSalary: 'Requested withdraw salary successfully!',
          rejectBonusSuccessful: 'Rejected bonus successfully!',
          rejectSalarySuccessful: 'Rejected Salary successfully!',
          removeAvatarSuccessful: 'Remove avatar successfully!',
          assignOfficeSuccessful: 'Assign office successfully!',
          sendAllEmailSuccessful: 'Send all email successfully!',
          blockComplaintSuccessful: 'Complaint was blocked successfully!',
          markComplaintSuccessful: 'Complaint was marked successfully!',
          unblockComplaintSuccessful: 'Unblocked complaint successfully',
          transferBonusSuccessful: 'Bonus was transferred successfully!',
          syncDataCompleted: 'Syncing your data completed!',
          signupSuccessful: 'You have successfully registered!',
          massUpdateEmployeesSuccessful: 'Mass update employees successfully!',
          closeComplaintSuccessful: 'Complaint has been closed!'
        },
        loading: {
          syncData: 'Syncing your data, we’ll notify you once it’s completed'
        }
      }
    },
    pages: {
      notFound: {
        header: '404: The page you are looking for isn’t here',
        subtitle: 'Please check if your url is entered correctly and you have the right permission'
      },
      approverBonusRequest: {
        all: 'All',
        to: '1 million - 5 million',
        to1: '5 million - 10 million',
        above: 'Above 10 million',
        below: 'Below 1 million',
        waiting: 'Waiting',
        approved: 'Approved',
        reject: 'Reject'
      },
      manageInformation: {
        officeName: 'Office name',
        numOfEmployees: 'No. of employees',
        numOfSalesTeam: 'No. of sales team',
        city: 'City',
        address: 'Address',
        officeManager: 'Office manager',
        office: 'Office',
        teamName: 'Team name',
        teamLeader: 'Team leader',
        fullName: 'Full name',
        Identity: 'National ID',
        username: 'Username',
        email: 'Email',
        manageBy: 'Managed by',
        role: 'Role',
        team: 'Team',
        phone: 'Phone',
        gender: 'Gender',
        status: 'Status',
        bonusStatus: 'Bonus Status',
        from: 'From',
        to: 'To',
        officeCode: 'Office code'
      },
      userPermission: {
        fullName: 'Full name',
        role: 'Role',
        userName: 'Username',
        email: 'Email',
        phone: 'Phone',
        male: 'Male',
        female: 'Female',
        gender: 'Gender',
        nationalId: 'National ID'
      },
      manageEmployees: {
        name: 'Name',
        role: 'Role',
        teamName: 'Team name',
        office: 'Office',
        account: 'Account',
        sendLink: 'Send account creation link',
        team: 'Team'
      },
      manageTeams: {
        all: 'All',
        to: '5 to 10',
        above: 'Above 10',
        below: 'Below 5'
      },
      manageOffice: {
        all: 'All',
        to: '500 to 1000',
        above: 'Above 1000',
        below: 'Below 500'
      },
      approverRequestBonus: {
        approverFailure: 'Failed to approve this bonus!'
      },
      bonusDetails: {
        complaintBonusBlocked:
          'Complaint of this bonus have been blocked! Complaint will unlock at ',
        markComplaint: 'Mark complaints',
        markComplaintPrompt:
          'Are you sure you want to block this complaint? If the claim mark exceeds 3 times, this account will be even appealed for 30 days.'
      },
      manageBonus: {
        dateRequestSalary: 'Available salary',
        dateRequestBonus: 'Available bonus',
        bonusStatistics: 'Bonus statistics',
        bonusSubcaption: 'Total Bonus statistics of the months',
        bonusYaxisname: 'Total bonus(vnđ)',
        bonusNumbersuffix: 'M',
        ftdStatistics: 'FTD statistics',
        ftdSubcaption: 'Total ftd statistics of the months',
        ftdYaxiname: 'Total ftd(USD)',
        ftdNumbersuffix: 'USD',
        customerStatistics: 'Customer statistics',
        customerSubcaption: 'Total customer statistics of the months',
        customerYaxiname: 'Total customer(person)',
        confirmWithdrawalRequest: 'Confirm withdrawal request',
        confirmWithdrawalContent:
          'Please double check the amount you request to withdraw and make sure the receiving account number is correct. Please feedback if there is an error before clicking confirm. If after you confirm the withdrawal and find out that there is an error regarding the amount requested for withdrawal or the account number receiving the funds, we will not be responsible.'
      },
      notification: {
        unblocked: 'unblocked complaint',
        requestBonus: 'Request bonus',
        requestSalary: 'Request salary',
        complaintBonus: 'Complained about the bonus',
        approveBonus: 'Has been approved your bonus',
        rejectBonus: 'Your bonus has been rejected',
        approveSalary: 'Has been approved your salary',
        rejectSalary: 'Your salary has been rejected',
        transferSalary: 'Transfer salary successfully',
        transferSalaryError: 'Transfer salary unsuccessfully',
        markComplaints: 'Mark Complaints',
        bannedComplaints: 'Complaints are forbidden',
        bannedComplaintsContent: 'Admin has locked your complaint function for 30 days.',
        markComplaintsContent: 'Admin has marked your complaints',
        transfer: 'Your bonus was transferred',
        blockedComplaint: 'blocked complaint of bonus',
        content: 'Content',
        from: 'Notifications from',
        typeNotifications: 'Type notifications',
        createAt: 'Time',
        by: 'by',
        typeRequestBonus: 'Request bonus',
        typeReport: 'Complaints',
        typeApprove: 'Approved bonus',
        typeReject: 'Rejected bonus',
        typeTransfer: 'Transferred bonus',
        notNotification: 'No notification',
        seeAll: 'See all'
      },
      signup: {
        loginInfo: 'Login information',
        personalInfo: 'Personal Information',
        bankAccountInfo: 'Bank account information',
        addressWallet: 'Address Wallet (USDT TRC20)'
      },
      settings: {
        qrCode: 'Wallet address(USDT) - QR Code'
      },
      userInformations: {
        chooseGender: 'Choose your gender',
        chooseBank: 'Choose a bank'
      },
      complaints: {
        createComplaints: 'Create complaint',
        answerComplaint: 'Answer Complaint',
        writeComplaint: 'Write Complaint',
        bonusOrSalary: 'Bonus or salary',
        technical: 'Technical error',
        typeComplaint: 'Type complaint',
        dateBonus: 'Monthly bonus',
        dateComplaint: 'Complaint date',
        content: 'Content',
        complaintDetail: 'Complaint details',
        closeComplaint: 'Close the complaint',
        complaintAboutBonus: 'Complaints about bonuses or salary',
        complaintAboutTechnical: 'Complaints about technical errors',
        closeComplaintPrompt:
          'Are you sure you want to delete this user? This action cannot be undone!',
        closeComplaintSuccessful: 'Complaint has been closed!',
        complaintBlocked: 'This complaint has been blocked! Complaint will unlock at ',
        bonusOf: 'Bonus of '
      }
    },
    notAvailable: 'Not Available',
    notAnswered: 'Not Answered',
    answered: 'Answered',
    read: 'Read',
    notClose: 'Opening',
    closed: 'Closed',
    markAsRead: 'Mark as read',
    markAsReadSuccessfully: 'Mark as read successfully',
    rejectContentBonus: 'Please enter the reason you declined the bonus!',
    noData: 'No data available!',
    tryAgain: 'Try again',
    acceptWithdrawalBonus: 'Bonus withdrawal confirmation',
    acceptWithdrawalSalary: 'Salary withdrawal confirmation',
    acceptWithdrawalSalaryBonus: 'Salary and Bonus withdrawal confirmation',
    cannotWithdrawalBonus: 'Cannot withdraw bonus',
    cannotComplaintBonus: 'Cannot complain to bonus',
    blockComplaint: 'Block complaint',
    unBlockComplaint: 'Unblock complaint'
  }
}

export default en
