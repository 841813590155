import { gql } from '@apollo/client'

export const GET_USER_PROFILE = gql`
  query getUserProfile($userId: String) {
    profile(where: { user_id: { _eq: $userId } }) {
      created_at
      date_of_birth
      email
      earned_point
      exp_level
      first_name
      id
      last_name
      location
      otp
      phone_number
      profile_picture_s3_url
      profile_picture_url
      updated_at
      user_id
      description
      role
    }
    getProfileSignedURL {
      url
    }
  }
`
