import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}))

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingLeft: 240
}))

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
})

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
})

const DashboardLayout = () => (
  <DashboardLayoutRoot>
    <DashboardSidebar />
    <DashboardLayoutWrapper>
      <DashboardLayoutContainer>
        <DashboardLayoutContent>
          <DashboardNavbar />
          <Outlet />
        </DashboardLayoutContent>
      </DashboardLayoutContainer>
    </DashboardLayoutWrapper>
  </DashboardLayoutRoot>
)

export default DashboardLayout
