import { Component, useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { ProviderContext, SnackbarProvider as SP, SnackbarProviderProps } from 'notistack'
import { Toaster } from 'react-hot-toast'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import * as React from 'react'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import GlobalStyles from './components/common/GlobalStyles'
import RTL from './components/common/RTL'
import SplashScreen from './components/common/SplashScreen'
import useAuth from './hooks/useAuth'
import useScrollReset from './hooks/useScrollReset'
import useSettings from './hooks/useSettings'
import routes from './routes'
import { createTheme } from './theme'
import { filterRoutesByRole } from './utils'
import './i18n'

const SnackbarProvider = SP as any

LicenseInfo.setLicenseKey(
  '5451179d3e694bdc7044198a2ea106c0Tz01MjA4MSxFPTE2OTY3MzMzODE5NjIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const App = () => {
  const { i18n } = useTranslation()
  const { settings } = useSettings()
  const auth = useAuth()
  const { role } = auth
  const content = useRoutes(filterRoutesByRole(role, routes))

  useScrollReset()

  useEffect(() => {
    const currentLang = localStorage.getItem('lang')
    if (currentLang) {
      i18n.changeLanguage(currentLang)
    }
    localStorage.setItem('lang', i18n.language)
  }, [])

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  })

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RTL direction={settings.direction}>
          <SnackbarProvider dense maxSnack={3}>
            <GlobalStyles />
            {auth.isInitialized ? content : <SplashScreen />}
          </SnackbarProvider>
        </RTL>
        <Toaster position="top-center" reverseOrder={false} containerStyle={{ marginTop: 62 }} />
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
